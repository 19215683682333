/* ---------------------------------- */
/* Generic styles ................... */
/* ---------------------------------- */

body {
  background: $ebony-clay;
  font-family: $font-family;
  color: $white;
  @include font-smoothing(on);
  font-weight: 400;
  font-size: 100%;
  line-height: 1.5em;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;

  &::before {
    content: '';
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url('../../assets/img/background.1.jpg');
  }
}

.scroll-touch {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body,
html {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100vw;
  height: 100vh;
}

input,
select,
textarea {
  transition: all $fast-transition cubic-bezier(0.42, 0, 0.58, 1);
}

a {
  transition: all $fast-transition cubic-bezier(0.42, 0, 0.58, 1);
  cursor: pointer;
  text-decoration: none;
  color: $white;

  &:hover {
    color: darken($caribbean-green, 5%);
    text-decoration: none !important;
    outline: none !important;
  }

  &:active,
  &:focus {
    outline: none !important;
    text-decoration: none !important;
    color: $white;
  }
}

button {
  transition: all $fast-transition cubic-bezier(0.42, 0, 0.58, 1);
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    text-decoration: none !important;
    color: $shark;
  }
}

strong,
b {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0;
  font-size: 1em;
  line-height: 1.6em;
  color: $white;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
  font-weight: 400;
  line-height: 1.6em;

  a {
    color: inherit;
    text-decoration: none;
  }

  small {
    color: inherit;
  }
}

h1 {
  font-size: 40px;
  font-family: $second-font;
  font-weight: 700;
}

h2 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 30px;
}

h3 {
  font-size: 30px;
  margin-bottom: 25px;
}

h4 {
  font-size: 25px;
  margin-bottom: 25px;
}

h5 {
  font-size: 20px;
  margin-bottom: 25px;
}

h6 {
  font-size: 15px;
  margin-bottom: 25px;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

.clear {
  clear: both;
}

.line-break {
  display: block;
  &::after {
    content: none;
  }
}

.display-none {
  display: none !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.opacity-0 {
  opacity: 0 !important;
  visibility: hidden !important;
}

.opacity-03 {
  opacity: 0.3 !important;
}

.opacity-1 {
  opacity: 1 !important;
  visibility: visible !important;
}

.index-999 {
  z-index: -999 !important;
}

/* ---------------------------------- */
/* Social Icons ..................... */
/* ---------------------------------- */

.social-icons {
  z-index: 9;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.7s cubic-bezier(0.42, 0, 0.58, 1);

  i {
    color: $white;
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
  }

  a {
    color: rgba($white, 1);
    width: 50px;
    line-height: 50px;
    letter-spacing: 0;
    background: transparent;
    font-size: 1em;
    font-weight: 300;
    height: 50px;
    display: inline-block;
    text-align: center;
    margin-right: 2px;
    border: none;
    transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);

    &:hover {
      color: rgba($caribbean-green, 1);
      background: transparent;

      i {
        color: rgba($caribbean-green, 1);
        transform: scale(1.2);
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* ---------------------------------- */
/* Media Queries .................... */
/* ---------------------------------- */

/* Large Devices, Wide Screens */
@include desktop {
}

/* Notebook devices */
@include notebook {
}

/* Medium Devices, Desktops */
@include medium {
  .overlay {
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.skew-part {
      @include skew(0, 0);
    }
  }
}

/* Small Devices, Tablets */
@include tablet {
  .info-contact {
    .item-map {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

/* Only for tablet in landscape mode */
@include landscape-tablet {
}

/* Only for phone in landscape mode */
@include landscape-phone {
}
