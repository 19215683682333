@import '../mixins';

.stock-item {
  margin-bottom: 1.5rem;
  border-radius: 0;

  .card-body {
    display: flex;
    flex-direction: row;

    color: $black;

    background-size: 0 0;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 250%;
      height: 250%;
      top: -50%;
      right: -110%;
      z-index: 1;
      opacity: 0.1;
      background-position: center;
      background-repeat: no-repeat;
      background-image: inherit;
      background-size: contain;
      transform: rotate(-30deg);
      filter: grayscale(0.3) blur(3px);
    }
  }

  .stock-symbol {
    width: 50px;
    height: 50px;

    padding: 5px 10px 5px 0;

    box-sizing: border-box;

    position: relative;
    z-index: 2;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .stock-expansion {
    flex-grow: 1;
    position: relative;
    z-index: 2;

    &-series,
    &-release {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  .stock-tags-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 2;

    li {
      display: inline-block;
      margin-right: 6px;
      font-size: 12px;

      .badge {
        background-color: $meadow !important;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  .stock-total {
    font-size: 32px;
    line-height: 50px;
    position: relative;
    z-index: 2;
    text-shadow: 0 0 2px $white, 0 0 2px $white, 0 0 2px $white, 0 0 2px $white;
  }
}

@include medium {
  .stock-list {
    column-count: 1;
    padding: 0;
  }
}

@include landscape-tablet {
  .stock-list {
    column-count: 1;
  }
}
