@import '../mixins';

.code-results {
  &-item {
    font-family: 'Courier New', Courier, monospace;
  }

  .card-body {
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      column-count: 3;
    }
  }

  .card-tabs {
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    border-radius: 0.375rem 0.375rem 0 0;

    background: var(--bs-card-cap-bg);
  }

  .row {
    padding: 0;
    color: #000;
  }

  .col {
    line-height: 24px;
  }

  .alert {
    margin-bottom: 0;
  }
}

@include phone {
  .code-results {
    .card-body {
      ul {
        column-count: 1;
      }
    }
  }
}

@include tablet {
  .code-results {
    .card-body {
      ul {
        column-count: 2;
      }
    }
  }
}
