// Modules
@import 'variables';
@import 'mixins';

/* Google Fonts */
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Montserrat:400,700');

// Reset
@import 'reset';

// Libraries
@import '~bootstrap/scss/bootstrap';
@import 'theme';
@import 'layout';

// Components
@import 'components/button';
@import 'components/codeCard';
@import 'components/codeResults';
@import 'components/codeModal';
@import 'components/codeSubmit';
@import 'components/packs';
@import 'components/qrReader';
@import 'components/userProfile';
@import 'components/stockList';
@import 'components/navigation';
@import 'components/preload';
@import 'components/cookieConsent';
@import 'components/achievement';
@import 'components/notification';
@import 'components/dialogModal';

// Animations
@import 'animations';
@import 'components/buttonFizzy';

// Pages
@import 'pages/home';
@import 'pages/base';
@import 'pages/login';
@import 'pages/myCodes';
@import 'pages/contact';
@import 'pages/notFound';
@import 'pages/terms';
@import 'pages/top';
@import 'pages/stats';
