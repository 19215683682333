.page-base {
  width: 100vw;
  padding: 3% 0;
  flex-grow: 1;
  background: $athens-gray;

  &-footnote {
    margin-top: 1.5rem;

    p {
      font-size: 12px;
    }
  }

  h1.text-intro {
    color: $ebony-clay;
  }

  .alert {
    border-radius: 0;
    margin: 0;

    a {
      color: $magical_pink;

      &:hover {
        color: rgba($magical_pink, 0.5);
      }
    }
  }

  .card {
    border-radius: 0;

    &-header {
      background: $peace;
      border-radius: 0;
    }

    &-body {
      border-radius: 0;
    }

    .nav-tabs {
      background: $peace;

      .nav-link {
        border-radius: 0;
        color: $ebony-clay;

        &:hover {
          border-color: transparent;
          background: $white;
        }
      }
    }
  }
}
