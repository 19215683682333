$size-md: 32px;
$size-lg: 40px;

.user-profile {
  height: $size-md;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;

  &-right {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  &-lg {
    height: $size-lg;

    .user-profile-icon {
      height: $size-lg;
      width: $size-lg;

      img,
      svg {
        height: $size-lg;
        width: $size-lg;
      }
    }

    .user-profile-details {
      height: $size-lg;
    }

    .user-profile-name,
    .user-profile-url {
      line-height: $size-lg - 8px;
    }
  }

  &-icon {
    display: block;
    height: $size-md;
    width: $size-md;
    border-radius: 50%;
    overflow: hidden;
    color: $white;
    text-align: center;

    &-twitter {
      background-color: $twitter;
    }

    &-reddit {
      background-color: $reddit;
    }

    img {
      width: $size-md;
      height: $size-md;
    }

    svg {
      margin: 5px;
      width: $size-md - 10;
      height: $size-md - 10;
    }
  }

  &-details {
    flex-grow: 1;
    height: $size-md;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
  }

  &-name {
    display: inline-block;
    line-height: $size-md - 8px;
    text-decoration: none;
  }

  &-url {
    display: inline-block;
    line-height: $size-md - 8px;
    text-decoration: none;
    color: $raven;
  }

  &-reddit {
    color: $reddit;

    &:hover {
      color: lighten($reddit, 20%);
    }
  }

  &-twitter {
    color: $twitter;

    &:hover {
      color: lighten($twitter, 20%);
    }
  }
}
