@import '../mixins';

.code-modal {
  &-backdrop {
    background: $ebony-clay;
    z-index: 999999;

    &.show {
      opacity: 0.92;
    }

    + .modal.show {
      z-index: 999999;
    }
  }

  &-close {
    width: 32px;
    height: 32px;

    position: absolute;
    top: 20px;
    left: 20px;

    line-height: 32px;
    text-align: center;

    color: $black;

    &:hover {
      cursor: pointer;
      color: $magical_pink;
    }
  }

  .modal-content {
    position: absolute;
    background-color: transparent;
    border: none;
  }

  .code-card {
    position: fixed;
    top: 50%;
    left: 50%;

    transform: translate3d(0, 0, 0);
    animation: 0.5s ease-in 0s 1 normal both running tilt-in-fwd-bl;

    &-copy {
      width: 20px;
      height: 20px;

      position: absolute;
      top: 269px;
      left: 465px;

      padding: 5px;
      box-sizing: border-box;

      color: $black;

      &:hover {
        cursor: pointer;
        color: $magical_pink;
      }
    }

    &-booster,
    &-promo {
      margin: -200px 0 0 -420px;
    }

    &-etb {
      margin: -200px 0 0 -500px;
    }

    &-footer {
      width: 480px;
      height: 32px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      position: absolute;
      top: 410px;
      left: 20px;
    }

    &-social {
      height: 32px;
      flex-grow: 1;

      button {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .user-profile {
      height: 32px;
      text-align: right;

      &-name {
        float: right;
      }
    }
  }

  .code-image {
    position: fixed;
    top: 50%;
    right: 50%;

    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    filter: drop-shadow(-5px 0px 5px rgba(0, 0, 0, 0.2));

    transform: translate3d(0, 0, 0);
    animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s 1 normal both running slide-in-bck-right;

    &-coupon {
      width: 305px;

      display: none;

      position: absolute;
      bottom: 200px;
      left: 25px;

      font-family: Arial, Helvetica, sans-serif;
      line-height: 32px;
      font-size: 22px;
      text-align: center;
      color: $black;
    }

    &-booster,
    &-promo {
      height: 650px;
      width: 355px;
      margin: -340px -420px 0 0;
      animation-name: slide-in-rotate-bck-right;
    }

    &-etb {
      width: 500px;
      height: 500px;
      margin: -250px -500px 0 0;
    }

    &-promo,
    &-unknown {
      .code-image-coupon {
        display: block;
      }
    }
  }
}

@include medium {
  .code-modal {
    .code-card {
      &-booster,
      &-promo {
        margin: -200px 0 0 -277.5px;
      }

      &-etb {
        margin: -200px 0 0 -250px;
      }
    }

    .code-image {
      z-index: -1;

      &-booster,
      &-promo {
        margin: -340px 0 0 0;
      }

      &-etb {
        margin: -250px 0 0 -250px;
      }

      &-promo,
      &-unknown {
        .code-image-coupon {
          display: block;
        }
      }
    }
  }
}

@include phone {
  .code-modal {
    .code-image {
      display: none;
    }

    .code-card {
      transform: scale(0.7) !important;
    }
  }
}
