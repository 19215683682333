@import '../mixins';

.my-codes {
  margin: 0;
  font-size: 15px;

  &-invalid {
    background-color: lighten($razzmatazz, 40) !important;

    td {
      color: $raven !important;
    }
  }

  &-container {
    padding: 0;
    border-radius: 0;
  }

  &-selector {
    float: right;

    margin: 14px 0 0 0;
    border-radius: 0;

    .btn {
      border-radius: 0;

      &:hover {
        opacity: 0.75;
      }

      &-primary {
        background: $ebony-clay;
        border-color: $ebony-clay;
        color: $meadow;
      }

      &-secondary {
        background: $white;
        border-color: $regent-gray;
        color: $meadow;
      }
    }
  }

  &-pagination {
    margin-bottom: 0;
    border-radius: 0;

    .page-link {
      cursor: pointer;

      &:not(.disabled) {
        color: $meadow;
      }

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;
      }
    }

    .page-item.active .page-link {
      background: $ebony-clay;
      border-color: $ebony-clay;
    }
  }

  &-copy {
    margin-left: 10px;
    cursor: pointer;

    &.fa-check {
      color: $meadow;
    }

    &:hover {
      color: $magical_pink;
    }
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 20px;
    font-weight: 600;
  }

  thead {
    th {
      font-weight: 600;
    }
  }

  tbody {
    td {
      vertical-align: middle;
      line-height: 32px;
    }

    .spinner-border,
    .my-codes-icon,
    img {
      width: 32px;
      height: 32px;
      margin: 0 10px 0 0;
      float: left;
    }
  }

  .btn-return-code {
    border-radius: 0;
    background-color: $meadow;
    border-color: $meadow;
  }
}

@include tablet {
  .my-codes {
    &-selector {
      float: none;
      width: 100%;
      margin: 0 0 1.5rem 0;
    }
  }
}
