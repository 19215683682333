@import '../mixins';

.primary-nav {
  &-isOpen {
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    #layout {
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }
  }

  &-show {
    &.shadow {
      .primary-nav-toggle {
        box-shadow: 0px 0px 5px rgba($ebony-clay, 0.25) !important;
        z-index: 999999;
      }
    }

    .primary-nav-toggle {
      position: relative;
      z-index: 999999;
      padding: 6px;
      background: $magical_pink;
      box-shadow: 0px 0px 5px rgba($ebony-clay, 0.25);

      &:hover {
        background: $meadow;
      }

      .fa-bars {
        display: none;
      }
      .fa-xmark {
        display: block;
      }
    }

    .primary-nav-overlay {
      z-index: -1;
      opacity: 1;
      transform: scale(160);
      backdrop-filter: blur(8px);
    }

    .primary-nav-container {
      overflow: hidden;
      pointer-events: auto;
      z-index: 99999;

      ul li {
        opacity: 1 !important;
        transform: translateX(0) !important;
      }
    }

    #layout {
      opacity: 0.5;
      filter: blur(5px);
      pointer-events: none;
    }
  }

  &.shadow {
    .primary-nav-toggle {
      box-shadow: 0px 0px 30px 20px rgba($ebony-clay, 0.5);
    }
  }

  &-toggle {
    height: 50px;
    width: 50px;
    padding: 9px 9px;
    margin: 14px 0 0 0;
    float: right;
    box-sizing: border-box;
    background: $meadow;
    box-shadow: 0px 0px 5px rgba($ebony-clay, 0.25);
    transition: background-color, box-shadow 0.3s;

    .fa-bars {
      display: block;
      width: 32px;
      height: 32px;
    }
    .fa-xmark {
      display: none;
      width: 38px;
      height: 38px;
    }

    &:hover {
      cursor: pointer;
      background: $magical_pink;
    }
  }

  &-overlay {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 2em;
    height: 2em;
    background: rgba($meadow, 0.9);
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease-in-out;
    will-change: transform;
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    ul {
      position: fixed;
      z-index: 101;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      list-style-type: none;

      padding: 0;
      margin: 0;

      overflow-x: hidden;
      overflow-y: auto;
      max-height: 100vh;

      li {
        padding: 6px 0;
        margin: 1em 3em;
        opacity: 0;
        // easeOutQuart
        transition: 0.6s cubic-bezier(0.365, 0.84, 0.44, 1);

        &:nth-child(odd) {
          transform: translateX(-100%);
        }

        &:nth-child(even) {
          transform: translateX(100%);
        }

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transition-delay: 0.05s * $i;
          }
        }

        a {
          position: relative;
          display: block;
          padding: 5px;
          font-family: Lato, sans-serif;
          font-size: 2em;
          text-decoration: none;
          text-transform: uppercase;
          color: white;
          transition: 0.5s;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba($magical_pink, 0.9);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}

@include medium {
  .primary-nav-toggle {
    right: 25px !important;
    top: 25px !important;
    position: fixed !important;
    margin: 0 !important;
  }

  .primary-nav-container {
    ul {
      align-items: center;
    }
  }
}

@include phone {
  .primary-nav-toggle {
    right: 20px !important;
    top: 20px !important;
  }
}
