.notification {
  border-radius: 0;

  &-container {
    position: fixed;
    top: 2rem;
    right: 2rem;
  }

  .toast-header {
    border-radius: 0;
    color: $raven;
  }

  .toast-body {
    color: $raven;
  }
}
