@use 'sass:math';
@import '../mixins';

$pack-width: 275;
$pack-height: 505;
$pack-width-xl: 325;
$pack-height-xl: 600;
$pack-width-xxl: 355;
$pack-height-xxl: 650;
$pack-gutter-h: 30;
$pack-gutter-w: 20;
$pack-count: 20;
$pack-columns: 4;

$height: ($pack-height + $pack-gutter-h) * math.div($pack-count, $pack-columns) + ($pack-gutter-h * 2);
$width: ($pack-width + $pack-gutter-w) * $pack-columns + ($pack-gutter-w * 2);

.packs {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  height: $height + px;
  box-sizing: border-box;
  padding-top: 1000px;
  top: 60%;
  margin-top: -#{math.div($height, 2) + px};
  opacity: 1;
  padding: $pack-gutter-w + px;
  width: $width + px;
  counter-reset: items;
  transition: opacity 0.5s;

  &::before,
  &::after {
    content: '';
    flex-basis: 100%;
    width: 0;
    order: #{$pack-columns - 1};
  }

  &-hidden {
    opacity: 0;
  }

  .item {
    width: $pack-width + px;
    height: $pack-height + px;
    position: relative;
    margin-bottom: $pack-gutter-h + px;
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(-5px 0px 5px rgba(0, 0, 0, 0.2));
  }
}

@for $i from 1 through $pack-columns {
  $columnOffset: $pack-height + (randomNumber(10, 30) * $i);
  $columnAnimationTime: randomNumber(60, 100);

  .packs .item:nth-child(#{$pack-columns + 'n'} + #{$i}) {
    order: $i;
    transform: translateY(#{if($i % 2 == 0, '-', '') + $columnOffset + px});
    animation: #{'pack-' + $i} #{$columnAnimationTime + 's'} infinite linear;

    @if $i != $pack-columns {
      margin-right: $pack-gutter-w + px;
    }
  }

  @keyframes #{'pack-' + $i} {
    50% {
      transform: translateY(#{if($i % 2 == 0, '', '-') + if($i % 2 == 0, 0, $columnOffset) + px});
    }
  }
}

@include media-breakpoint-up(xl) {
  $width: ($pack-width-xl + $pack-gutter-w) * $pack-columns + ($pack-gutter-w * 2);
  $height: ($pack-height-xl + $pack-gutter-h) * math.div($pack-count, $pack-columns) + ($pack-gutter-h * 2);

  .packs {
    width: $width + px;
    height: $height + px;
    margin-top: -#{math.div($height, 2) + px};

    .item {
      width: $pack-width-xl + px;
      height: $pack-height-xl + px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  $width: ($pack-width-xxl + $pack-gutter-w) * $pack-columns + ($pack-gutter-w * 2);
  $height: ($pack-height-xxl + $pack-gutter-h) * math.div($pack-count, $pack-columns) + ($pack-gutter-h * 2);

  .packs {
    width: $width + px;
    height: $height + px;
    margin-top: -#{math.div($height, 2) + px};

    .item {
      width: $pack-width-xxl + px;
      height: $pack-height-xxl + px;
    }
  }
}

@include phone {
  .packs {
    display: none;
  }
}
