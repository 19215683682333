.btn-fizzy {
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04);
  transition: all 0.3s, box-shadow 0.2s, transform 0.2s 0.2s;

  i.l {
    position: relative;
    left: -19px;
    top: 20px;
    color: #00c4ff;
    font-size: 25px;
    opacity: 0;
    transition: left 0.3s 0s, top 0.3s 0s, opacity 0.3s 0s;
  }

  &:hover {
    color: #2c3940;
    background: white;
    box-shadow: 0px 17px 18px -14px rgba(0, 0, 0, 0.08);
    span.t {
      left: 16px;
      transition: left 0.4s;
    }
    i.l {
      top: 12px;
      opacity: 1;
      transition: left 0.3s 0s, top 0.3s 0.1s, opacity 0.3s 0.1s;
    }
  }

  &:hover .button-spots {
    @for $i from 1 through 19 {
      &:nth-of-type(#{$i}) {
        animation: spot-#{$i} 0.7s calc(0.4 / random(10)) + calc(random(10) / 10) + s linear infinite;
      }
    }
    @for $i from 20 through 40 {
      &:nth-of-type(#{$i}) {
        animation: spot-#{$i} 0.7s calc(0.4 / random(10)) + calc(random(10) / 10) + s linear infinite;
      }
    }
    @for $i from 40 through 46 {
      &:nth-of-type(#{$i}) {
        animation: spot-#{$i} 0.7s calc(0.4 / random(10)) + calc(random(10) / 10) + s linear infinite;
      }
    }
    @for $i from 46 through 54 {
      &:nth-of-type(#{$i}) {
        animation: spot-#{$i} 0.7s calc(0.4 / random(10)) + calc(random(10) / 10) + s linear infinite;
      }
    }
  }

  .button-spots {
    position: absolute;
    border-radius: 100px;
    background: $white;
    opacity: 0;
    animation: opacity 1s;

    @for $i from 1 through 52 {
      &:nth-of-type(#{$i}) {
        transform-origin: 90px - random(10) 20px - random(10);
        background: if($i % 2 == 0, $meadow, $white);
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
        transition: all 1s + calc(random(10) / 10);
      }
    }

    @for $i from 1 through 19 {
      &:nth-child(#{$i}) {
        padding: random(3) + 2 + px;
        left: -25 + ($i * 13.5) + px;
        top: 50 + px;
      }
    }
    @for $i from 20 through 39 {
      &:nth-child(#{$i}) {
        padding: random(3) + 2 + px;
        left: -255 + ($i * 12) + px;
        top: -12 + px;
      }
    }
    @for $i from 40 through 45 {
      &:nth-child(#{$i}) {
        padding: random(3) + 2 + px;
        right: -10px;
        top: -488 + ($i * 12) + px;
      }
    }

    @for $i from 46 through 52 {
      &:nth-child(#{$i}) {
        padding: random(3) + 2 + px;
        left: -10px;
        top: -568 + ($i * 12) + px;
      }
    }
  }
}

@for $i from 1 through 20 {
  @keyframes spot-#{$i} {
    from {
      opacity: 0;
    }
    to {
      transform: translateY(30px) translatex(-20px + $i * 2);
      opacity: 0.6;
    }
  }
}
@for $i from 20 through 40 {
  @keyframes spot-#{$i} {
    from {
      opacity: 0;
    }
    to {
      transform: translateY(-30px) translatex(-50px + $i * 2);
      opacity: 0.6;
    }
  }
}
@for $i from 40 through 45 {
  @keyframes spot-#{$i} {
    from {
      opacity: 0;
    }
    to {
      transform: translateY(-86px + $i * 2) translatex(40px);
      opacity: 0.6;
    }
  }
}
@for $i from 46 through 54 {
  @keyframes spot-#{$i} {
    from {
      opacity: 0;
    }
    to {
      transform: translateY(-99px + $i * 2) translatex(-40px);
      opacity: 0.6;
    }
  }
}

@keyframes opacity {
  from {
  }
  to {
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    opacity: 0.8;
  }
  to {
    transform: rotate(360deg);
    opacity: 0.8;
  }
}

@keyframes down {
  from {
    left: 10px;
  }
  to {
    left: 57px;
  }
}

@keyframes spew {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes final {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes finalbox {
  from {
  }
  to {
    width: 50px;
  }
}
@keyframes tick {
  from {
  }
  to {
    transform: scale(1) rotate(-90deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  20% {
    transform: translate(-10px, 0) rotate(-20deg);
  }
  30% {
    transform: translate(10px, 0) rotate(20deg);
  }
  50% {
    transform: translate(-10px, 0) rotate(-10deg);
  }
  60% {
    transform: translate(10px, 0) rotate(10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
