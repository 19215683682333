.page-terms {
  width: 100vw;
  padding: 3% 0;
  flex-grow: 1;
  background: $athens-gray;

  &-updated {
    text-transform: uppercase;
    color: $raven;
    margin-bottom: 2rem;
  }

  h1 {
    margin: 0;
    color: $raven;
    font-weight: bolder;
  }

  h2 {
    margin: 1.5rem 0;
    color: $raven;
    font-weight: bolder;
  }

  h5 {
    margin: 1rem 0;
    color: $raven;
    font-weight: bold;
  }

  p {
    margin-bottom: 1.5rem;
    color: $raven;
  }

  ol {
    list-style-type: decimal;
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    color: $raven;

    li {
      padding-left: 1rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: $meadow;

    &:hover {
      color: $magical_pink;
    }
  }
}
