$achievement-color: #77a2e9;

.achievement {
  &-banner {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: transparent;
    overflow: hidden;

    &-lg {
      width: 600px;
      left: calc(50% - 300px);
      background-color: $achievement-color;
    }

    &.animated {
      animation: mainAnimationFrames linear 8s;
      animation-delay: 1;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards; /*when the spec is finished*/
    }
  }

  &-text {
    font-size: 2em;
    position: absolute;
    width: (400px);
    top: calc((100px - 50%) - 1.6em);
    left: 105px;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #fff;
  }

  &-message {
    position: relative;
    width: 100%;
    animation: achievementTextAnimationFrames linear 2.5s;
    animation-delay: 0.7s;
    top: -13px;
    opacity: 0;
    top: -77px;

    padding-top: 3px;
  }

  &-title {
    position: absolute;
    width: 100%;
    font-size: 0.8em;
    top: 75px;
    margin-top: 2px;

    animation: achievementNameAnimationFrames linear 3.8s;
    animation-delay: 2.8s;
    opacity: 0;
  }

  &-description {
    position: absolute;
    width: 100%;
    font-size: 0.5em;
    top: 75px;
    margin-top: 40px;

    animation: achievementNameAnimationFrames linear 3.5s;
    animation-delay: 3.3s;
    opacity: 0;
  }
}

@keyframes achievementTextAnimationFrames {
  0% {
    opacity: 0;
    top: -77px;
  }

  40% {
    opacity: 1;
  }

  55% {
    opacity: 1;
    top: -77px;
    right: 0;
  }

  100% {
    opacity: 0;
    top: -138px;
  }
}

@keyframes achievementNameAnimationFrames {
  0% {
    opacity: 0;
    top: 75px;
  }

  20% {
    opacity: 1;
    top: 9px;
  }

  90% {
    opacity: 1;
    top: 9px;
    right: 0;
  }

  100% {
    opacity: 0;
    top: 9px;
  }
}

@keyframes mainAnimationFrames {
  0% {
    background-color: transparent;
  }

  7.5% {
    background-color: $achievement-color;
    width: 600px;
    height: 100px;
    left: calc(50% - 300px);
  }

  80% {
    width: 600px;
    left: calc(50% - 300px);
  }

  92% {
    width: 600px;
    height: 100px;
    left: calc(50% - 300px);
    background-color: $achievement-color;
  }

  99% {
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    background-color: transparent;
  }
}
