.page-home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // position: fixed;
  // top: 0;

  .overlay {
    &-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: visible;
      width: 100%;

      &-hide {
        cursor: pointer;
        z-index: 999;
      }
    }

    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    border-right: 1px solid darken($ebony-clay, 5%);

    &-right {
      left: 50%;
      right: 0;
    }

    &.skew-part {
      @include skew(-25, 0);
    }

    &.rotate-part {
      top: -10%;
      @include rotate(25deg);
      overflow: visible;
    }

    &.hide-part {
      opacity: 0;
    }
  }

  .alert a {
    color: $radical-red;

    &:hover {
      color: lighten($radical-red, 10%);
    }
  }

  #left-side {
    width: 50vw;
    height: 100vh;

    box-sizing: border-box;
    padding: 5% 0 32px 5%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 50px;

    transition: all $medium-transition cubic-bezier(0, 0, 0.58, 1);

    .content {
      position: relative;
      z-index: inherit;

      width: 100%;
      flex-grow: 1;

      box-sizing: border-box;
      padding: 0;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;

      @include font-smoothing;
      text-align: left;

      transition: all $medium-transition cubic-bezier(0, 0, 0.58, 1);

      .call-to-action {
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 32px;
        }

        a,
        button {
          text-align: center;
          margin: 0 !important;
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

.body-home {
  #layout {
    max-height: 100vh;
    height: 100vh;
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .grecaptcha-badge {
    visibility: visible;
  }
}

@include notebook {
  .page-home {
    #left-side {
      padding: 5% 0 12px 5%;

      .content {
        h1.text-intro {
          max-width: 100%;
        }

        h2.text-intro {
          max-width: 75%;
        }

        .line-break {
          content: ' ';
          width: 5px;
          display: inline-block;
        }

        .call-to-action {
          width: 80%;
          max-width: 80%;
          min-width: 80%;

          ul {
            padding: 0 20% 0 0;
            flex-direction: column;
          }
        }
      }
    }
  }
}

@include medium {
  .body-home {
    #layout {
      overflow-y: scroll;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  }

  .page-home {
    height: auto;
    position: relative;
    overflow: initial;

    #left-side {
      width: 100vw;
      padding: 15% 5% 0 5%;

      .content {
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        flex-grow: 0;

        .text-intro {
          max-width: 75%;
        }

        .call-to-action {
          width: 75%;
          max-width: 75%;
          min-width: 75%;

          ul {
            padding: 0 20%;
            flex-direction: column;
          }
        }
      }

      .footer {
        p {
          text-align: center;
        }
      }
    }

    #right-side {
      position: fixed;

      overflow-x: hidden;
      overflow-y: auto;

      display: block;

      width: 100vw;
      height: 100vh;
      transform: translate3d(0, 0, 0);

      &.hide-right {
        transform: translate3d(200%, 0, 0);
      }
    }
  }
}

@include landscape-tablet {
  .page-home {
    #left-side {
      padding: 10% 5% 0 5%;
    }
  }
}

@include tablet {
  .page-home {
    #left-side {
      padding: 25% 5% 0 5%;

      .content {
        h1.text-intro,
        h2.text-intro {
          max-width: 90%;
        }

        .call-to-action {
          width: 60%;
          max-width: 60%;
          min-width: 60%;

          ul {
            padding: 0;
          }
        }
      }
    }
  }
}

@include landscape-phone {
  .page-home {
    #left-side {
      padding: 15% 5% 0 5%;

      .content {
        h1.text-intro,
        h2.text-intro {
          max-width: 90%;
        }
      }
    }
  }
}

@include phone {
  .page-home {
    #left-side {
      padding: 35% 5% 0 5%;

      .content {
        h1.text-intro,
        h2.text-intro {
          max-width: 100%;
        }

        .call-to-action {
          width: 80%;
          max-width: 80%;
          min-width: 80%;

          ul {
            padding: 0;
          }
        }
      }
    }
  }
}
