@import '../mixins';

.top {
  &-donor-row {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      .rank {
        color: #c9b037;
      }
    }

    &:nth-child(2) {
      .rank {
        color: #b4b4b4;
      }
    }

    &:nth-child(3) {
      .rank {
        color: #6a3805;
      }
    }

    .card-body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .rank {
      line-height: 50px;
      font-size: 30px;
      width: 80px;
      font-weight: bolder;
      color: $raven;
      padding: 0 15px 0 0;
      border-right: 1px solid $raven;
      margin: 0 20px 0 0;
      text-align: center;
    }

    .user-profile.user-profile-lg {
      height: 50px;
      flex-grow: 1;

      .user-profile-icon {
        height: 50px;
        width: 50px;

        img {
          height: 50px;
          width: 50px;
        }
      }
    }

    .stats-row {
      padding: 0;
      margin: 0;
      height: 50px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;

      li {
        height: 50px;
        box-sizing: border-box;
        padding: 10px 0 10px 35px;
        position: relative;
        background-size: 0 0;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 26px;
          height: 26px;
          margin-top: -13px;
          background-size: cover;
          background-image: inherit;
          background-repeat: no-repeat;
          opacity: 0.5;
        }

        line-height: 30px;
        font-size: 30px;
        color: $black;

        &.stats-row-total {
          padding-left: 28px;

          &::before {
            width: 22px;
            height: 22px;
            margin-top: -11px;
          }
        }

        &.stats-row-booster {
          padding-left: 26px;
        }

        img {
          max-width: 40px;
          float: left;
        }
      }
    }
  }
}

@include tablet {
  .top {
    &-donor-row {
      .stats-row-booster,
      .stats-row-etb,
      .stats-row-promo,
      .user-profile-url,
      .rank {
        display: none;
      }
    }
  }
}

@include phone {
  .top {
    &-donor-row {
      .user-profile-url {
        display: none;
      }
    }
  }
}
