/* ----------------------------------------------
 * Generated by Animista on 2022-9-16 20:35:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@keyframes tilt-in-fwd-bl {
  0% {
    transform: rotateY(-20deg) rotateX(-35deg) translate(-600px, 600px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-right
 * ----------------------------------------
 */
@keyframes slide-in-bck-right {
  0% {
    transform: translateZ(700px) translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-rotate-bck-right {
  0% {
    transform: translateZ(700px) translateX(400px) rotate(0);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0) rotate(-5deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-bck
 * ----------------------------------------
 */
@keyframes flip-diagonal-2-bck {
  0% {
    transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    transform: translateZ(-260px) rotate3d(-1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-fwd
 * ----------------------------------------
 */
@keyframes flip-diagonal-2-fwd {
  0% {
    transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    transform: translateZ(160px) rotate3d(-1, 1, 0, 180deg);
  }
}
