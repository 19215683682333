.page-not-found {
  flex-grow: 1;

  display: flex;
  justify-content: center;

  .text-center {
    padding: 5% 0 0 0;
  }

  .text-danger {
    color: $meadow !important;
  }

  .light-btn {
    width: 80%;
    margin: 0 10%;
  }
}
