.light-btn {
  background: transparent;
  font-weight: 600;
  padding: 0.7em 2em;
  color: $white;
  margin-right: 10px;
  border: 2px solid $white;
  display: block;
  float: left;

  &:hover {
    background: $white;
    color: $shark;
    border-color: $white;
  }
}

.action-btn {
  background: $caribbean-green;
  font-weight: 600;
  padding: 0.7em 2em;
  color: $white;
  border: 2px solid $caribbean-green;
  display: block;
  float: left;

  &:hover {
    background: $white;
    color: $shark;
    border-color: $white;
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.75;
  }

  &-container {
    position: relative;
    .popover {
      border-color: lighten($radical-red, 10%);
      background-color: $radical-red;

      &-arrow::before,
      &-arrow::after {
        border-top-color: $radical-red !important;
      }

      &-body {
        color: $white;
        text-align: center;
      }
    }

    .action-btn-message {
      width: 200px;
      height: 20px;

      position: absolute;
      left: 0;
      top: 60px;
      margin: 0 0 0 -18px;

      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
  }
}
