.page-login {
  background: rgba($ebony-clay, 0.92);
  z-index: 999999;

  &.show {
    opacity: 1;
  }

  .modal-content {
    background-color: $athens-gray;
    border-radius: 0;
  }

  .modal-header {
    border-bottom: none;

    h1.text-intro {
      color: $ebony-clay;
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  .modal-body {
    .action-btn {
      &-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        box-sizing: border-box;
        padding: 3rem;
        list-style: none;
        margin: 0;

        li {
          width: 100%;

          &.message {
            border-top: 1px solid $raven;
            margin: 1rem 0 0 0;
            padding: 1.5rem 0 0 0;

            p {
              margin: 0;
              color: $raven;
            }
          }

          .action-btn {
            width: 100%;
          }
        }
      }

      &-reddit {
        background-color: $reddit;
        border-color: $reddit;
      }

      &-twitter {
        background-color: $twitter;
        border-color: $twitter;
      }
    }
  }
}
