/*
* PHLY - Versatile Coming Soon Template
* Build Date: October 2015
* Last Update: October 2018
* Author: Madeon08
* Copyright (C) 20XX Madeon08
* This is a premium product available exclusively here : https://themeforest.net/user/Madeon08/portfolio
*/

// WebKit font-smoothing
// Values: none, antialiased (default), subpixel-antialiased
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Cross-browser mixins transition cubic-bezier
@mixin bezier-transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

// Cross-browser mixins border-radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Cross-browser mixins rotate
@mixin rotate($rotate) {
  transform-origin: center left;

  -ms-transform: rotate($rotate);
  /* IE 9 */
  -webkit-transform: rotate($rotate);
  /* Chrome, Safari, Opera */
  -moz-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin skew($x, $y) {
  transform: skew(#{$x}deg, #{$y}deg);
}

@function randomNumber($min, $max) {
  $rand: random();
  @return $min + floor($rand * (($max - $min) + 1));
}

// n is number of stars required
@function multiple-box-shadow($n) {
  $value: '#{random(2500)}px #{random(2500)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2500)}px #{random(2500)}px #FFF';
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

// Devices Media Queries
$special-phone: 750px;
$special-tablet: 1024px;

// General Media Queries
$phone-width: 480px;
$tablet-width: 768px;
$medium-width: 1024px;
$notebook-width: 1280px;
$desktop-width: 1600px;

@mixin landscape-phone {
  @media screen and (max-device-width: $special-phone) and (orientation: landscape) {
    @content;
  }
}

@mixin landscape-tablet {
  @media only screen and (max-device-width: $special-tablet) and (orientation: landscape) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: $phone-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (max-width: $medium-width) {
    @content;
  }
}

@mixin notebook {
  @media only screen and (max-width: $notebook-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop-width) {
    @content;
  }
}
