@import 'mixins';

#layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 100vh;

  background: rgba($ebony-clay, 0.92);
  backdrop-filter: blur(5px);

  .header {
    z-index: 999999;
    width: 100vw;
    padding: 3% 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    &-home {
      position: absolute;
      top: 0;
    }

    .container-fluid {
      padding: 0 5%;
    }

    .brand-logo {
      position: relative;
      z-index: inherit;

      max-width: 225px;

      transition: none;

      &:hover {
        img {
          filter: drop-shadow(0 0 3px $meadow);
        }
      }

      &-flag {
        position: absolute;
        color: $meadow;
        right: 6px;
        top: -14px;
      }

      img {
        max-width: 225px;
        overflow: visible;
      }
    }
  }

  .footer {
    position: relative;
    z-index: 99999;

    width: 100%;
    padding: 10px 0;
    background: transparent;
    text-align: center;
    display: flex;
    bottom: 0;

    p {
      font-size: 11px;
      font-weight: 100;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $raven;
      flex-grow: 1;
      line-height: 50px;
      text-align: left;

      a {
        color: $meadow;

        &:hover {
          color: $magical_pink;
        }
      }
    }
  }
}

@include medium {
  #layout {
    .header {
      padding-top: 80px;
      position: relative;

      &-home {
        .brand-logo {
          left: 50%;
          margin-left: -112.5px;
          top: 100px !important;
        }
      }

      .brand-logo {
        position: absolute;
        top: 16px;

        &-flag {
          top: 0;
        }
      }
    }
  }
}

@include landscape-tablet {
  #layout {
    .header {
      .brand-logo {
        img {
          max-width: 200px !important;
        }
      }
    }
  }
}

@include landscape-phone {
  #layout {
    .header {
      &-home {
        .brand-logo {
          top: 100px;
        }
      }
    }
  }
}

@include phone {
  #layout {
    .header {
      .brand-logo {
        img {
          max-width: 200px !important;
        }
      }
    }
  }
}
