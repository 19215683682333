.dialog-modal {
  color: #636363;
  width: 450px;

  &.modal-dialog {
    margin-top: 80px;
  }

  &-success {
    .icon-box,
    .btn {
      background: $meadow;
    }

    .btn {
      &:hover,
      &:focus {
        background: lighten($meadow, 10%);
      }
    }
  }

  &-danger {
    .icon-box,
    .btn {
      background: $radical-red;
    }

    .btn {
      &:hover,
      &:focus {
        background: lighten($radical-red, 10%);
      }
    }
  }

  .modal-content {
    padding: 20px;
    border-radius: 0;
    border: none;
  }

  .modal-header {
    border-bottom: none;
    position: relative;
  }

  .h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
    color: $raven;
  }

  p {
    color: $raven;
  }

  .close {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
  }

  .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    padding: 16px 14px 16px 14px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

    i {
      font-size: 58px;
      position: relative;
      top: 3px;
    }
  }

  .btn {
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }
}
