.page-stats {
  width: 100vw;
  padding: 3% 0;
  flex-grow: 1;

  .bg-meadow {
    background-color: $meadow;
  }

  .bg-buttercup {
    background-color: $buttercup;
  }

  .bg-curious-blue {
    background-color: $curious-blue;
  }

  .bg-zest {
    background-color: $zest;
  }

  .stats-date-range {
    margin-bottom: 1.5rem;
    border-radius: 0;

    .card-body {
      .card-title {
        font-size: 0.8125rem;
        color: $raven;
        margin-bottom: 0;
      }

      .recharts-wrapper {
        position: relative;
        overflow: hidden;
      }
    }
  }

  .stats-card {
    margin-bottom: 1.5rem;
    border-radius: 0;

    .card-title {
      font-size: 0.8125rem;
      color: $raven;
      margin-bottom: 0;
    }

    .h2 {
      color: $black;
      font-weight: bold;
      margin-bottom: 0;
    }

    .col-auto {
      width: auto;
      max-width: none;
      flex: 0 0 auto;
    }

    .icon {
      width: 3.45rem;
      height: 3.45rem;

      &-shape {
        display: inline-flex;
        padding: 12px;
        text-align: center;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
