.qr-reader {
  position: relative;
  width: 100%;
  height: 500px;
  padding: 0;
  margin: 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../../img/qr.overlay.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  > div {
    width: 100%;
    height: 100%;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    padding: 0 !important;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative !important;
    }
  }

  &-inputDevice {
    position: absolute;
    z-index: 10;
    border-radius: 0;
    width: auto;
    bottom: 10px;
    right: 10px;
  }
}
