.code-card {
  width: 333px;
  height: 240px;

  &-md {
    width: 444px;
    height: 320px;

    .code-card-qr {
      width: 120px;
      height: 120px;
      top: 160px;
      left: 32px;
    }
  }

  &-lg {
    width: 555px;
    height: 400px;

    .code-card-qr {
      width: 150px;
      height: 150px;
      top: 200px;
      left: 40px;
    }
  }

  &-xl {
    width: 777px;
    height: 560px;

    .code-card-qr {
      width: 210px;
      height: 210px;
      top: 280px;
      left: 56px;
    }
  }

  > svg {
    width: 100%;
    height: 100%;
  }

  &-qr {
    position: absolute;
    width: 90px;
    height: 90px;
    top: 120px;
    left: 24px;
  }

  #Header {
    a {
      &:hover {
        fill: $magical_pink;
      }
    }
  }

  #ID {
    rect {
      fill: transparent;
    }
    text {
      display: block;

      width: 218px;
      height: 46.8px;
      line-height: 46.8px;

      font-size: 28px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: left;
      color: $black;
    }
  }

  #Code {
    rect {
      fill: transparent;
    }
    text {
      display: block;

      width: 498px;
      height: 76.8px;
      line-height: 76.8px;

      font-size: 50px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: left;
      color: $black;
    }
  }

  #Coupon {
    rect {
      fill: transparent;
    }
    text {
      display: block;

      width: 408px;
      height: 46.8px;
      line-height: 46.8px;

      font-size: 28px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: left;
      color: $black;
    }
  }
}
