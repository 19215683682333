.CookieConsent {
  background: $ebony-clay !important;
  flex-wrap: nowrap;
  align-items: center !important;

  .content-wrapper {
    display: inline-block;
    padding: 1rem;
    margin: 0 !important;
  }

  .btn-wrapper {
    display: inline-block;
    padding: 1rem;
  }
}

.CookieOverlay {
  background: rgba($ebony-clay, 0.5) !important;
}
