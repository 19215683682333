// Duration.
$fast-transition: 0.2s;
$medium-transition: 0.3s;
$long-transition: 0.6s;

// Font properties
$font-family: 'Open Sans', 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;
$second-font: Montserrat, 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;

// Weight
$font-weight: 400;
$font-weight-bold: 600;

// Main colors
$white: #ffffff;
$black: #000000;
$shark: #2b2d35;
$gallery: #efefef;
$ebony-clay: #20232d;
$bright-gray: #323a45;
$tuna: #1f222e;
$peace: #a4b0be;
$magical_pink: #ff0082;

// Flash color
$razzmatazz: #ef106d;
$school-bus-yellow: #ffd800;

// Custom colors
$torch-red: #ff1d4d;
$meadow: #1abc9c;
$buttercup: #f1c40f;
$curious-blue: #3498db;
$zest: #e67e22;
$wisteria: #9b59b6;
$caribbean-green: #00c8aa;
$raven: #757a86;
$darky-shark: #0a1a24;

// Agency
$dark-blue: #323a45;
$radical-red: #ff3366;
$dark-shark: #191d22;
$regent-gray: #939da5;
$pacifico-font: Pacifico, 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;

// FLAT
$sharky-flat: #282931;
$scarpa-flow: #555560;
$varela-font: 'Varela Round', 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;

// SPACE
$cod-gray: #0c0c0c;
$blue: #0f33ff;

// Secondary colors
$athens-gray: #f2f3f7;
$black-gloss: #292f33;
$lynch: #6c7a89;
$mine-shaft: #333333;
$alabaster: #f8f8f8;
$concrete: #f2f2f2;
$clouds: #ecf0f1;
$silver: #bababa;
$silver-chalice: #b2b2b2;
$dusty-gray: #999999;
$boulder: #777777;
$dove-gray: #747c83;
$light-gray: #f7f7f7;
$deep-black: #111111;
$port-gore: #23224e;

// Alert forms colors
$green-alert: #27ae60;
$red-alert: #cf000f;

// Social Media Brand Colors */
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$soundcloud: #ff3a00;
$behance: #1769ff;
$reddit: #ff4500;
