@import '../mixins';

.bulk-code-container {
  padding: 2px !important;
  position: relative;
  border-bottom: 1px solid var(--bs-border-color-translucent);

  .bulk-code-editor {
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    padding: 10px 10px 80px 10px;
    color: $black;

    resize: vertical;
    border: none;
    transition: none;

    font-family: 'Courier New', Courier, monospace;

    &[placeholder]:empty:before {
      content: attr(placeholder);
      position: absolute;
      color: gray;
      background-color: transparent;
    }

    .text-placeholder {
      display: inline-block;
    }
  }

  .btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border-radius: 0;

    &:hover {
      background: $shark;
      color: $white;
      border-color: $shark;
    }
  }
}

.single-code-container {
  padding: 2px !important;
  position: relative;

  .toast {
    background-color: $white;

    &-body {
      color: $black;
    }
  }
}

.code-submit {
  border-radius: 0;

  .card-header,
  .nav-link,
  .alert {
    border-radius: 0;
  }

  .nav-item .nav-link {
    color: $white;

    &:hover,
    &.active {
      color: $raven;
      background: $white;
    }
  }

  .card-header {
    background-color: $raven;
  }
}

@include phone {
  .card-submit-container {
    margin-bottom: 1.5rem;
  }
}
